<template>
  <div class="mx-auto" style="max-width: 480px;">
    <section-header title="Albums" class="pb-10"></section-header>
    <!-- <h1 class="text-center mb-6">Albums</h1> -->
    <div class="gallery">
      <template v-for="(album, $index) in albumList">
        <figure
          :key="album.alt"
          class="gallery__item"
          :class="`gallery__item--${$index + 1}`"
          :onclick="`window.open('//youtube.com/watch?v=${album.yt_id}', '_blank');`"
          :title="album.alt"
        >
          <img
            :src="require(`@/assets/img/site/albums/${album.src}`)"
            class="gallery__img"
            :alt="album.alt"
          />
          <div class="centered">
            <v-btn fab dark x-small color="red">
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </div>
        </figure>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    albumList: [
      {
        src: 'roddur.jpg',
        alt: 'Chilte Rod',
        yt_id: 'G7VtPrV2emk'
      },
      {
        src: 'bondhu.jpeg',
        alt: 'Bondhu',
        yt_id: 'yvcEvMrBFiY'
      },
      {
        src: 'majlis.jpg',
        alt: 'Majlis',
        yt_id: 'C1bvjo8Tkrc'
      },
      {
        src: 'meghpakhi.jpg',
        alt: 'Meghpakhi',
        yt_id: 'PAzRBRz8WZk'
      },
      {
        src: 'nanaronge.jpg',
        alt: 'Nana Ronge',
        yt_id: 'KaI-52yzB-o'
      },
      {
        src: 'antamil.jpeg',
        alt: 'Antamil',
        yt_id: '9dZXll4FVb4'
      },
      {
        src: 'kamalgeeti.jpg',
        alt: 'Kamalgeeti',
        yt_id: 'LIDVuGt_6ms'
      }
    ]
  }),
  components: {
    SectionHeader: () => import('@/components/reusable/SectionHeader')
  }
}
</script>

<style lang="scss" scoped>
.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(6, 5vw);
  grid-gap: 12px;
  @media only screen and (max-width: 600px) {
    grid-template-rows: repeat(6, 10vw);
  }
}
.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery__item {
  position: relative;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
  }
}
.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
}
.gallery__item--2 {
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 3;
}
.gallery__item--3 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 5;
}
.gallery__item--4 {
  grid-column-start: 4;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 5;
}
.gallery__item--5 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 7;
}
.gallery__item--6 {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 5;
  grid-row-end: 7;
}
.gallery__item--7 {
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 7;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
